import './style.css'
import * as THREE from 'three'
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
//import * as dat from 'dat.gui'

//texture loader
const loader = new THREE.TextureLoader()
loader.setPath('/textures/')
const height = loader.load('height.png')
const texture = loader.load('texture.jpg')
const alpha = loader.load('alpha.png')

// Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Objects
const geometry = new THREE.PlaneBufferGeometry(3,3,64,64)

// Materials
const material = new THREE.MeshStandardMaterial({
    color: 'gray',
    map:texture,
    displacementMap:height,
    displacementScale:.7,
    alphaMap:alpha,
    transparent:true,
    depthTest:false
})

// Mesh
const plane = new THREE.Mesh(geometry,material)
scene.add(plane)
plane.rotation.x = 12

// gui.add(plane.rotation, 'x').min(0).max(600)
// gui.add(plane.rotation, 'y').min(0).max(600)
// gui.add(plane.rotation, 'z').min(0).max(600)


// Lights
//color was 0xffd700
const getRandomElementFromList = (list) => list[Math.floor(Math.random() * (list.length))]
const pointLight = new THREE.PointLight(getRandomElementFromList([0xebe7dc, 0xffd700, 0x00af00]), 5.2)
pointLight.position.x = 1.1
pointLight.position.y = 2
pointLight.position.z = 4.8
scene.add(pointLight)

// gui.add(pointLight.position,'x')
// gui.add(pointLight.position,'y')
// gui.add(pointLight.position,'z')

// const lightColor = {
//     color:pointLight.color.getHex()
// }
// gui.addColor(lightColor, 'color').onChange(()=>{
//     pointLight.color.set(lightColor.color)
// })
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = -.5
camera.position.y = -.1
camera.position.z = 1
camera.rotation.x = .4
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha:true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */

let mouseY = 0
document.addEventListener('mousemove',(event)=>{
    mouseY = event.clientY
})

const clock = new THREE.Clock()

const tick = () =>
{

    const elapsedTime = clock.getElapsedTime()

    // Update objects

    plane.rotation.z = .5*elapsedTime
    plane.material.displacementScale = .1 + mouseY * .0008

    // Update Orbital Controls
    // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()